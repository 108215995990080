import React, { useEffect, useState } from "react";

function LoginPage() {
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [aesValue, setAesValue] = useState("");

    const handleEnterClick = () => {
        setShowForm(true);
    };
    const handleLogin = () => {
        setLoading(true);
        setError("");

        // Simulating a login delay
        setTimeout(() => {
            setLoading(false);
            setError("The account or password is invalid. Please try again.");
        }, 2000);
    };
    const lazyLoadCSS = (href) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = href;
        document.head.appendChild(link);
    };

    // Function to dynamically load scripts
    const loadScript = (url) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.type = "text/javascript";
        document.head.appendChild(script);
    };

    // Load required CSS and JS files on mount
    useEffect(() => {
        const cssFiles = ["https://cdn.gclite.org/login/css/synolog.css"];
        const jsFiles = [
            "https://dreamx.red/webman/desktop.js",
            "https://dreamx.red/synoSDSjslib/sds.js",
            "https://dreamx.red/scripts/scrollbar/flexcroll.js",
            "https://dreamx.red/scripts/ext-3/ux/ux-all.js",
            "https://dreamx.red/webapi/entry.cgi?api=SYNO.Core.Desktop.SessionData&amp;version=1&amp;method=getjs&amp;SynoToken=&amp;v=1614856593",
            "https://dreamx.red/webapi/entry.cgi?api=SYNO.Core.Desktop.Defs&amp;version=1&amp;method=getjs&amp;v=1632122186",
            "https://dreamx.red/webapi/entry.cgi?api=SYNO.Core.Desktop.JSUIString&amp;version=1&amp;method=getjs&amp;lang=enu&amp;v=1617733016-s7",
            "https://dreamx.red/webapi/entry.cgi?api=SYNO.Core.Desktop.UIString&amp;version=1&amp;method=getjs&amp;lang=enu&amp;v=1617733028-s7",
            "https://dreamx.red/webapi/entry.cgi?api=SYNO.API.Info&version=1&method=query&query=SYNO.API.Auth,SYNO.FileStation",
            "https://dreamx.red/scripts/prototype-1.7.2/prototype.js",
            "https://dreamx.red/scripts/ext-3/adapter/ext/ext-base.js",
            "https://dreamx.red/scripts/ext-3/ext-all.js",
            "https://dreamx.red/webman/security.cgi",
        ];
        if (showForm) {
            // Simulate an API call to fetch AES value
            const fetchAesValue = async () => {
                // In a real-world scenario, replace this with a real API call
                const response = await fetch("https://dreamx.red/webman/modules/ControlPanel/modules/dsm.cgi");
                const data = await response.json();
                setAesValue(data.aesValue); // Dynamically set the AES value
            };

            fetchAesValue();
        }
        cssFiles.forEach(lazyLoadCSS);
        jsFiles.forEach(loadScript);
    }, [showForm]);

    return (
        <div className="ext-strict synohdpack">
            {/* Background Video and Audio */}
            <div id="sds-login-background">
                <video
                    className="sds-login-background"
                    loop
                    muted
                    preload="auto"
                    autoPlay
                    draggable="false"
                    src="https://cdn.gclite.org/videos/index.mp4"
                    type="video/mp4"
                ></video>
                <audio
                    loop
                    autoPlay
                    playsInline
                    src="https://cdn.gclite.org/audios/13.mp3"
                    draggable="false"
                ></audio>
            </div>
            {!showForm ? (
                <div className="center">
                    <button className="neon-button" onClick={handleEnterClick}>
                        Enter
                    </button>
                </div>
            ) : (
                <div id="sds-login" className="sds-login-dark">
                    <div className="sds-login-welcome-info">
                        <div className="sds-login-cuslogo-wrapper">
                            <img
                                draggable="false"
                                src="https://cdn.gclite.org/images/logo.svg"
                                width="72px"
                                id="sds-login-cuslogo-img"
                                alt="DreamX Logo"
                            />
                        </div>
                        <div className="sds-login-welcome-info-wrapper">
                            <div className="sds-login-welcome-info-container">
                                <div
                                    className="sds-login-welcome-info-title"
                                    title="Welcome To The Realm Of Dreams"
                                >
                                    Welcome To The Realm Of Dreams
                                </div>
                            </div>
                            <div className="sds-login-welcome-info-container">
                                <div
                                    className="sds-login-welcome-info-msg"
                                    title="We Have Been Expecting You"
                                >
                                    We Have Been Expecting You
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="sds-login-dialog">
                        <div id="sds-login-dialog-title" title="DreamX">
                            Dream🤍
                        </div>
                        <div
                            id="sds-login-dialog-form"
                            className="x-plain extra-padding x-plain-noborder x-form-label-left syno-ux-formpanel syno-ux-formpanel-no-fbar syno-ux-formpanel-no-tbar"
                            role="log"
                            aria-live="assertive"
                        >
                            <form
                                id="login-form"
                                method="POST"
                                action="https://dreamx.red/webman/login.cgi?enable_syno_token=yes"
                                target="login_iframe"
                            >
                                <div
                                    id="login-inner-panel"
                                    className="x-panel login-inner-panel syno-ux-panel x-panel-noborder"
                                >

                                    <div className="x-panel-bwrap">
                                        <div className="sds-icon-text-field">
                                            <div className="icon user-icon"></div>
                                            <input
                                                className="x-form-text x-form-field textfield x-form-focus"
                                                id="login_username"
                                                name="username"
                                                maxLength="256"
                                            />
                                        </div>
                                        <div className="sds-icon-text-field">
                                            <div className="icon passwd-icon"></div>
                                            <input
                                                type="password"
                                                className="x-form-text x-form-field textfield"
                                                id="login_passwd"
                                                name="passwd"
                                                maxLength="256"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="form-checkbox">
                                            <input
                                                type="checkbox"
                                                id="rememberme"
                                                name="rememberme"
                                                className="x-form-checkbox x-form-field"
                                                aria-label="Stay signed in"
                                            />
                                            <label htmlFor="rememberme" className="syno-ux-checkbox-label">
                                                Stay signed in
                                            </label>
                                        </div>
                                        <span id="login-btn"
                                            className="x-btn syno-ux-button syno-ux-button-blue x-btn-noicon">
                                            <em className="x-unselectable"
                                                unselectable="on">
                                                <button
                                                    type="button"
                                                    aria-label="Sign In"
                                                    className="x-btn-text"
                                                    onClick={handleLogin}
                                                    disabled={loading}
                                                >Sign In
                                                </button>
                                            </em>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="x-form-item  x-hide-label x-hide-display">
                            <label for="sds-login-dialog-status" className="x-form-item-label"></label>
                            <div className="x-form-element" id="x-form-el-sds-login-dialog-status" >
                                {loading && <div id="sds-login-dialog-status" name="sds-login-dialog-status" className=" x-form-display-field x-hide-display" aria-readonly="false" aria-required="false" aria-disabled="false"></div>}
                                {error && <div className="x-form-hidden-error-msg">{error}</div>}
                                <div role="presentation" className="x-form-hidden-error-msg"></div>
                            </div>
                            <div className="x-form-clear-left"></div>
                        </div>
                        <input
                            type="hidden"
                            name="__cIpHeRtExT"
                            value="{{ aes_value }}"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default LoginPage;
